import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import {
  useCurrentAssessmentId,
  useSetCurrentAssessmentId,
  useSetCurrentCompanyId,
} from 'models/localState';
import Loader from '../components/Loader';
import { getServerSidePropsFunc } from 'utils/i18n/server';
import { withNoNavigation } from 'components/topMenu/Navigation';
import { useCurrentUser } from 'models';
import { FirstCompanyModal } from 'components/WelcomeScreen';
import { useSimpleCompany } from 'hooks/useCompany';

const Redirect = () => {
  const { replace } = useRouter();
  const user = useCurrentUser();
  const setCurrentCompanyId = useSetCurrentCompanyId();
  const company = useSimpleCompany();
  const currentAssessmentId = useCurrentAssessmentId();
  const setCurrentAssessmentId = useSetCurrentAssessmentId();

  useEffect(() => {
    if (company)
      if (company.isPortfolioOwner) {
        replace('/portfolios');
      } else {
        const landingAssessmentId = currentAssessmentId || company.cAssessments?.[0]?.id;
        if (landingAssessmentId) {
          if (landingAssessmentId !== currentAssessmentId) {
            setCurrentAssessmentId(landingAssessmentId, true);
          }
          replace(`/assessments/${currentAssessmentId}`);
        } else {
          replace('/assessments');
        }
      }
    else if (company === null) setCurrentCompanyId('');
  }, [company, user, currentAssessmentId]);

  return user?.companies.length === 0 || (user && !company) ? (
    <FirstCompanyModal user={user} />
  ) : (
    <Loader height="100vh" />
  );
};

export default withNoNavigation(Redirect, { loginRequired: true });
export const getServerSideProps = getServerSidePropsFunc(['common']);
